import React from 'react';
import { IonItem, IonInput, IonIcon } from '@ionic/react';
import { eye, eyeOff, calendarClearOutline } from 'ionicons/icons';
import DatePicker, { getDefaultLocale } from 'react-datepicker';
import ValidationMessage from '../ValidationMessage';
import { withTranslation } from 'react-i18next';
import DAInputLabel from './DAInputLabel';
import { getCms } from '../../services/CmsService';

interface DAInputProps {
    type?: "password" | "text" | "email" | "date" | undefined;
    value?: any;
    onChange?: Function;
    labelText?: string;
    isInvalid?: boolean;
    isFormSubmited?: boolean;
    errorMessage?: any;
    cssClass?: string;

    min?: string;
    max?: string;
    minDate?: Date;
    maxDate?: Date;
    availableDates?: Array<Date>;
    minCalendarDetail?: "month" | "year" | "decade" | "century";
    dropdownMode?: "scroll" | "select" | undefined;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    yearDropdownItemNumber?: number;

    placeholder?: string;
    tooltipIcon?: string;
    tooltipContent?: Array<string> | string;
    autocomplete?: boolean;
    onFocus?: React.FocusEventHandler<HTMLIonInputElement>;
}

interface DAInputState {
    type: "password" | "text" | "email" | "date" | undefined;
    value: string;

    calendarPosition : {
        top: number;
        right: number;
    },
    availableDates?: Array<Date>;
    locale: string;
}

class DAInput extends React.Component<DAInputProps, DAInputState> {
    datepickerRef: React.RefObject<DatePicker>;

    constructor(props: DAInputProps) {
        super(props);

        this.datepickerRef = React.createRef();

        this.state = {
            type: props.type ? props.type : "text",
            value: props.value,
            calendarPosition: {
                top: 0,
                right: 0
            },
            availableDates: props.availableDates ? this.filterAvailebleDates(props.availableDates) : undefined,
            locale: getDefaultLocale()
        }
    }

    componentDidMount() {
        if (this.state.type === "date") {
            window.addEventListener('localeChange', () => {
                this.setState({ locale: getDefaultLocale() });
            });
        }
    }

    filterAvailebleDates = (availableDates: Array<Date>):  Array<Date> => {
        let uniqueDates = new Array<Date>();
        availableDates.forEach((date) => {
            let isUniqueDate = uniqueDates.some(uniqueDay => 
                uniqueDay.getFullYear() !== date.getFullYear() &&
                uniqueDay.getDate() !== date.getDate() &&
                uniqueDay.getMonth() !== date.getMonth()
            )
            if(isUniqueDate) {
                uniqueDates.push(date);
            }
        })
        return uniqueDates;
    }

    onIconClick = (e: any) => {
        
        if(this.props.type === "password") { 
            this.setState({type: this.state.type ? undefined : "password" });
        }
        if(this.props.type === "date") {
            this.setState({
                calendarPosition: this.getElementPosition(e.target)
            });

            this.datepickerRef.current?.setOpen(true);
        }
    }

    getElementPosition = (element: HTMLElement) => {
        let position = element.getBoundingClientRect();
        return {
            top: position.top + position.height + 10,
            right: window.innerWidth - position.right - 16
        }
    }

    get type() {
        //TODO: add check for iOS and Droid
        if(this.state.type === "date") {
            return "text";
        }
        return this.state.type;
    }

    get value() {
        return this.props.value;
    }

    onChange = (e: any) => {
        if(this.props.onChange) {
            this.props.onChange(e)
        }
    }

    isSomeError = () : boolean => {
        return this.props.isInvalid || (this.props.isFormSubmited !== undefined && this.props.isFormSubmited && this.props.errorMessage !== null && this.props.errorMessage !== undefined)
    }

    render() {
        return (
            <div>
                <DAInputLabel labelText={this.props.labelText} 
                    tooltipIcon={this.props.tooltipIcon} 
                    tooltipContent={this.props.tooltipContent} />
                
                <IonItem className={`da-input-wrap${this.isSomeError() ? " da-input-invalid" : ""}`}>
                    {this.renderinput()}
                    {this.renderIcon()}
                </IonItem>
                
                <ValidationMessage message={this.props.isFormSubmited ? this.props.errorMessage : null} />
            </div>
        );
    }

    renderIcon = () => {
        if(this.props.type === "password") {
            return <IonIcon slot="end" className="da-input-icon" icon={this.state.type ? eye : eyeOff} onClick={this.onIconClick} />
        }
        
        if(this.props.type === "date") { 
            return <IonIcon slot="end" className="da-input-icon" icon={calendarClearOutline} onClick={this.onIconClick} />
        }
    }

    renderinput = () => {
        if(this.state.type === "date") {
            return (
                <DatePicker ref={this.datepickerRef}
                    selected={this.value ? new Date(this.value) : null}
                    onChange={this.onChange}
                    peekNextMonth
                    locale={this.state.locale}
                    showMonthDropdown={this.props.showMonthDropdown}
                    showYearDropdown={this.props.showYearDropdown}
                    dropdownMode={this.props.dropdownMode}
                    dateFormat={getCms("common.dateformat")}
                    popperPlacement="bottom-end"
                    popperClassName="da-datepicker-popup"
                    // @ts-ignore - needed for the Popper.Modifiers obj, details: https://github.com/Hacker0x01/react-datepicker/issues/3180
                    popperModifiers={[
                        {
                          name: "offset",
                          options: {
                            offset: [58, -3],
                          },
                        }, 
                        {
                          name: "flip",
                          options: {
                            fallbackPlacements: ['top'],
                          },
                        }, 
                        {
                          name: "preventOverflow",
                          options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                          },
                        },
                      ]}
                    includeDates={this.props.availableDates}
                    adjustDateOnChange={false}
                    yearDropdownItemNumber={this.props.yearDropdownItemNumber}
                    maxDate={this.props.maxDate}
                    minDate={this.props.minDate}
                    strictParsing
                    portalId="root-portal" />
            )
        } else {
            return (
                <IonInput 
                    type={this.type}
                    className={`da-input ${this.props.cssClass ?? ''}`.trim()}
                    autocomplete={this.props.autocomplete ? 'on' : 'new-password'}
                    min={this.props.min}
                    max={this.props.max}
                    value={this.value}
                    placeholder={this.props.placeholder ?? ''}
                    onIonChange={this.onChange}
                    onFocus={this.props.onFocus}
                    />
            )
        }
    }
};

export default  withTranslation()(DAInput);